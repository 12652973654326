import { Button, Form } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import newWithdrawalImage from '../../assets/images/new-withdrawal.png'
import NumberInputField from '../../atoms/form/NumberInputField'
import { FORM } from '../../utils/enums'
import { formatAsCurrency } from '../../utils/helpers'
import validateCreateWithdrawalForm from './validateCreateWithdrawalForm'

interface ICreateWithdrawalForm {
	handleCreateWithdrawalDrawerClose: () => void
	createWithdrawalFormSubmitSuccess: boolean
}

export interface ICreateWithdrawalFormValues {
	amount: number
	availableEarnings: number
}

const CreateWithdrawalForm: FC<InjectedFormProps<ICreateWithdrawalFormValues, ICreateWithdrawalForm> & ICreateWithdrawalForm> = ({
	handleCreateWithdrawalDrawerClose,
	createWithdrawalFormSubmitSuccess,
	initialValues,
	handleSubmit,
	submitting
}) => {
	const { t } = useTranslation()

	return createWithdrawalFormSubmitSuccess ? (
		<div className={'max-w-[21rem] mx-auto'}>
			<img src={newWithdrawalImage} alt={''} className={'mx-auto mb-3'} style={{ width: '240px' }} />
			<h4 className={'text-center text-36 font-700 leading-sm mb-3'}>
				{t('loc:employee|Your request')}
				<br />
				{`${t('loc:employee|is on its way')}!`}
			</h4>
			<p className={'text-center text-15 font-400 mb-6'}>
				{`${t('loc:employee|A withdrawal request has been sent')},`}
				<br />
				{`${t('loc:employee|we will let you know once it’s approved')}.`}
			</p>
			<Button type={'primary'} shape={'round'} block={true} onClick={handleCreateWithdrawalDrawerClose}>
				{t('loc:general|Okay')}
			</Button>
		</div>
	) : (
		<Form noValidate={true} onSubmitCapture={handleSubmit} className={'flex flex-col items-center create-withdrawal-form'}>
			<h4 className={'text-22 lg:text-56 font-700 mb-12 lg:mb-10'}>
				<span className={'inline lg:block'}>{t('loc:employee|How much money')} </span>
				<span className={'inline lg:block'}>{t('loc:employee|do you want to withdraw')}?</span>
			</h4>
			<div className={'max-w-[25rem]'}>
				<Field name={'amount'} component={NumberInputField} precision={2} placeholder={t('loc:general|Amount')} className={'mx-8 mb-3'} />
				<div className={'inline-flex items-center gap-1 text-gray-500 mb-12 lg:mb-6'}>
					<span className={'text-15 lg:text-17 font-400'}>{`${t('loc:employee|Your available Balance')}:`}</span>
					<span className={'text-17 lg:text-20 font-600 p-0 border-none'}>{formatAsCurrency(initialValues.availableEarnings)}</span>
				</div>
				<Button type={'primary'} shape={'round'} block={true} htmlType={'submit'} loading={submitting}>
					{t('loc:employee|Send request')}
				</Button>
			</div>
		</Form>
	)
}

export default reduxForm<ICreateWithdrawalFormValues, ICreateWithdrawalForm>({
	form: FORM.WITHDRAWAL_CREATE,
	validate: validateCreateWithdrawalForm,
	touchOnChange: true,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(CreateWithdrawalForm)
