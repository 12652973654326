import { Empty, Spin, Table, TableColumnProps } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import StateItem from '../../../atoms/StateItem'
import UserAvatar from '../../../atoms/UserAvatar'
import { RootState } from '../../../redux'
import { companiesLoadList } from '../../../redux/companies/companiesActions'
import { IWithdrawalsLoadListPayload } from '../../../redux/withdrawals/withdrawalsActions'
import { IWithdrawal } from '../../../types/interfaces'
import { formatAsCurrency } from '../../../utils/helpers'
import { IWithdrawalsListFilterValues } from './WithdrawalsListFilter'

interface IWithdrawalsListTable {
	filter: IWithdrawalsListFilterValues
	setFilter: (filter: IWithdrawalsListFilterValues) => void
}

const WithdrawalsListTable: FC<IWithdrawalsListTable> = ({ filter, setFilter }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const list: IWithdrawalsLoadListPayload = useSelector((state: RootState) => state.withdrawals.list)

	const [dataSource, setDataSource] = useState<IWithdrawalsLoadListPayload['records']>(list.records)

	const columns: TableColumnProps<IWithdrawal>[] = [
		{
			title: t('loc:admin|Received'),
			key: 'createdDate',
			dataIndex: 'createdDate',
			render: (value: IWithdrawal['createdDate']) => <span>{dayjs(value).format('DD.MM.YYYY - HH:mm')}</span>,
			width: 216,
			ellipsis: true
		},
		{
			title: t('loc:admin|Employee'),
			key: 'employee',
			render: (record: IWithdrawal) => <UserAvatar user={record.employee} showName={true} />,
			width: 280,
			className: 'max-w-[332px]',
			ellipsis: true
		},
		{
			title: t('loc:general|Amount'),
			key: 'amount',
			dataIndex: 'amount',
			render: (value: IWithdrawal['amount']) => <span>{formatAsCurrency(value)}</span>,
			width: 240,
			ellipsis: true
		},
		{
			title: t('loc:admin|Company'),
			key: 'company',
			render: (record: IWithdrawal) => <span>{record.employee.company.name}</span>,
			width: 280,
			className: 'max-w-[332px]',
			ellipsis: true
		},
		{
			title: t('loc:admin|Contract'),
			key: 'contractStatus',
			render: (record: IWithdrawal) => <StateItem state={record.employee.contractStatus} />,
			width: 280,
			className: 'max-w-[332px]',
			ellipsis: true
		},
		{
			title: t('loc:admin|State'),
			key: 'state',
			dataIndex: 'state',
			render: (value: IWithdrawal['state']) => <StateItem state={value} />,
			width: 148,
			ellipsis: true
		}
	]

	useEffect(() => {
		dispatch(companiesLoadList())
	}, [dispatch])

	useLayoutEffect(() => {
		setDataSource(list.records)
	}, [list.records])

	return (
		<Table
			scroll={{ x: true }}
			loading={{
				indicator: <Spin size={'large'} />,
				spinning: list.isLoading
			}}
			columns={columns}
			dataSource={dataSource}
			rowKey={(record) => record.id}
			onRow={(record) => ({
				onClick: () => navigate(`${t('paths:withdrawals')}/${record.id}`)
			})}
			locale={{ emptyText: <Empty description={!list.isLoading && t('loc:general|No Data')} /> }}
			pagination={{
				position: ['bottomCenter'],
				showSizeChanger: false,
				hideOnSinglePage: true,
				total: list.pagination?.totalCount,
				current: list.pagination?.page,
				pageSize: list.pagination?.limit
			}}
			onChange={(pagination) => setFilter({ ...filter, page: pagination.current })}
		/>
	)
}

export default WithdrawalsListTable
