import { Col, Row } from 'antd'
import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import employeeImage from '../assets/images/login-employee.png'
import employerImage from '../assets/images/login-employer.png'
import { isAdminRoute } from '../utils/helpers'

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation()

	return (
		<section className={'h-100% bg-gradient-to-tl from-purple-base to-pink'}>
			<Row align={'middle'} className={'h-100%'}>
				<Col xs={{ span: 0 }} lg={{ span: 7 }} xl={{ span: 8 }} className={'h-100% relative'}>
					{isAdminRoute ? (
						<img
							src={employerImage}
							alt={''}
							className={'absolute z-1 bottom-0 right-0 transform translate-x-50% xl:translate-x-40% origin-bottom-left scale-80% xl:scale-100%'}
							style={{ minWidth: '650px', maxWidth: '650px' }}
						/>
					) : (
						<img
							src={employeeImage}
							alt={''}
							className={'absolute z-1 top-50% right-0 transform -translate-y-50% translate-x-50% origin-left scale-90% xl:scale-100%'}
							style={{ minWidth: '430px', maxWidth: '430px' }}
						/>
					)}
				</Col>
				<Col xs={{ span: 24 }} lg={{ span: 17 }} xl={{ span: 16 }} className={'h-100% relative flex justify-center bg-white lg:rounded-l-xl'}>
					<div className={'w-100% lg:w-auto my-7 lg:my-[16vh] mx-4 lg:mx-0 transform lg:translate-x-25%'}>
						<h1 className={'text-center text-26 lg:text-56 font-700 mb-12'}>{t('loc:general|WageNow')}</h1>
						<div className={'w-100% max-w-[40rem] lg:w-[21.5rem] mx-auto flex flex-col'}>{children}</div>
					</div>
					<a
						href={'https://www.wagenow.sk/gdpr'}
						className={'absolute bottom-4 right-4 text-13 text-gradient'}
						target={'_blank'}
						rel={'noopener noreferrer'}
					>
						{t('loc:general|Privacy policy')}
					</a>
				</Col>
			</Row>
		</section>
	)
}

export default AuthLayout
