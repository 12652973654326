import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { isIOS, isMacOS, isInStandaloneMode } from '../utils/helpers'

const AddToHomeScreenBanner: React.FC = () => {
	const { t } = useTranslation()

	const [prompt, setPrompt] = useState<Event | null>(null)
	const [isIOSPrompt, setIsIOSPrompt] = useState(false)
	const [isMacOSPrompt, setIsMacOSPrompt] = useState(false)

	const getIsDismissed = useCallback(() => sessionStorage.getItem('home_banner_dismissed'), [])

	useEffect(() => {
		if ((isIOS() || isMacOS()) && !isInStandaloneMode() && !getIsDismissed()) {
			setIsIOSPrompt(isIOS())
			setIsMacOSPrompt(isMacOS())
		}
	}, [getIsDismissed])

	useEffect(() => {
		const handler = (event: Event) => {
			event.preventDefault()
			if (getIsDismissed()) return
			setPrompt(event)
		}
		window.addEventListener('beforeinstallprompt', handler)
		return () => {
			window.removeEventListener('beforeinstallprompt', handler)
		}
	}, [getIsDismissed])

	const dismiss = (setDismissed: boolean) => {
		if (prompt) setPrompt(null)
		if (isIOSPrompt) setIsIOSPrompt(false)
		if (isMacOSPrompt) setIsMacOSPrompt(false)
		if (setDismissed) sessionStorage.setItem('home_banner_dismissed', 'True')
	}

	const handleInstallClick = async () => {
		try {
			if (!prompt) return
			;(prompt as any).prompt()
			const choiceResult = await (prompt as any).userChoice
			if (choiceResult.outcome === 'accepted') {
				dismiss(false)
			}
		} catch (installerror) {
			Sentry.captureException(installerror)
			console.error('Installation failed', installerror)
		}
	}

	if (isIOSPrompt) {
		return (
			<div className={'flex items-center absolute left-4 bottom-10 bg-purple-light shadow-lg p-4 rounded-md max-w-1/5 mr-4'}>
				<div>
					<p>{t('loc:general|Add to home screen')}:</p>
					<ul className={'list-disc list-inside'}>
						<li>{t('loc:general|Press the Share button')}</li>
						<li>{t('loc:general|Select "add to home screen"')}</li>
						<li>{t('loc:general|Press the "add" button')}</li>
					</ul>
				</div>
				<CloseOutlined onClick={() => dismiss(true)} className={'ml-4'} />
			</div>
		)
	}

	if (isMacOSPrompt) {
		return (
			<div className={'flex items-center absolute left-4 bottom-10 bg-purple-light shadow-lg p-4 rounded-md max-w-1/5 mr-4'}>
				<div>
					<p>{t('loc:general|Add to home screen on macOS')}:</p>
					<ul className={'list-disc list-inside'}>
						<li>{t('loc:general|Click the "Share" button in the toolbar')}</li>
						<li>{t('loc:general|Select "Add to dock"')}</li>
						<li>{t('loc:general|Click "Add"')}</li>
					</ul>
				</div>
				<CloseOutlined onClick={() => dismiss(true)} className={'ml-4'} />
			</div>
		)
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{!!prompt && (
				<div className={'flex items-center absolute left-4 bottom-10 bg-purple-light shadow-lg p-4 rounded-md max-w-1/5 mr-4'}>
					<p>{t('loc:general|Add to home screen')}</p>
					<Button type={'primary'} shape={'round'} onClick={handleInstallClick} className='text-16 max-w-32 max-h-10 ml-4'>
						{t('loc:general|Install')}
					</Button>
					<CloseOutlined onClick={() => dismiss(true)} className={'ml-4'} />
				</div>
			)}
		</>
	)
}

export default AddToHomeScreenBanner
