import i18next from 'i18next'
import { FormErrors } from 'redux-form'
import { ICreateWithdrawalFormValues } from './CreateWithdrawalForm'

export default (values: ICreateWithdrawalFormValues) => {
	const errors: FormErrors<ICreateWithdrawalFormValues> = {}

	if (!values.amount) {
		errors.amount = i18next.t('loc:general|This field is required')
	} else if (values.amount < 0.01) {
		errors.amount = i18next.t('loc:general|Amount cannot be zero or negative')
	} else if (values.amount > values.availableEarnings) {
		errors.amount = i18next.t('loc:general|Amount cannot be higher then your available balance')
	}

	return errors
}
