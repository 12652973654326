/* eslint-disable object-shorthand */

import { Dayjs } from 'dayjs'
import { isNil, map, omitBy } from 'lodash'
import { IWithdrawalDetailHistoryFilterValues } from '../../pages/withdrawals/detail/WithdrawalDetailHistory'
import { IWithdrawalsListFilterValues } from '../../pages/withdrawals/list/WithdrawalsListFilter'
import { Paths } from '../../types/api'
import { USERS_CONTRACT_STATUS, WITHDRAWAL_STATE } from '../../utils/enums'
import { mapOrderByToParams, setDate, setWithdrawalState } from '../../utils/helpers'
import { IWithdrawalsLoadDetailPayload, IWithdrawalsLoadListPayload } from './withdrawalsActions'

export const mapWithdrawalsLoadListRequest = (filter: IWithdrawalsListFilterValues): Paths.GetApiAdminWithdrawals.QueryParameters => {
	const { page, limit, search, state, company, order } = filter

	return omitBy(
		{
			page: page,
			limit: limit,
			search: search,
			withdrawalStatus: state,
			companyID: company,
			orderBy: mapOrderByToParams(order).orderBy,
			orderDirection: mapOrderByToParams(order).orderDirection
		},
		isNil
	) as Paths.GetApiAdminWithdrawals.QueryParameters
}

export const mapWithdrawalsLoadListByUserRequest = (filter: IWithdrawalDetailHistoryFilterValues): Paths.GetApiV1UsersUserIdWithdrawals.QueryParameters => {
	const { page, limit, exceptWithdrawalIDS } = filter

	return omitBy(
		{
			page: page,
			limit: limit,
			exceptWithdrawalIDS: exceptWithdrawalIDS
		},
		isNil
	) as Paths.GetApiV1UsersUserIdWithdrawals.QueryParameters
}

export const mapWithdrawalsLoadListResponse = (
	payload: Paths.GetApiAdminWithdrawals.Responses.$200
): Omit<IWithdrawalsLoadListPayload, 'isLoading' | 'isFailure'> => {
	const { withdrawals, pagination } = payload
	return {
		records: map(
			withdrawals,
			({
				withdrawalID,
				createdAt,
				updatedAt,
				amount,
				status,
				owner: {
					userID,
					firstName,
					lastName,
					company: { companyID, name },
					contractStatus
				}
			}) => ({
				id: withdrawalID,
				createdDate: setDate(createdAt) as Dayjs,
				updatedDate: setDate(updatedAt) as Dayjs,
				amount: amount,
				state: setWithdrawalState(status) as WITHDRAWAL_STATE,
				employee: {
					id: userID,
					firstName: firstName,
					lastName: lastName,
					company: {
						id: companyID,
						name: name
					},
					contractStatus: contractStatus as USERS_CONTRACT_STATUS
				}
			})
		),
		pagination: pagination
	}
}

export const mapWithdrawalsLoadDetailResponse = (
	payload: Paths.GetApiAdminWithdrawalsWithdrawalId.Responses.$200
): Omit<IWithdrawalsLoadDetailPayload, 'isLoading' | 'isFailure'> => {
	if (payload.withdrawal) {
		const {
			withdrawalID,
			createdAt,
			updatedAt,
			amount,
			status,
			owner: {
				userID,
				firstName,
				lastName,
				company: { id, name },
				contractStatus
			}
		} = payload.withdrawal

		return {
			record: {
				id: withdrawalID,
				createdDate: setDate(createdAt) as Dayjs,
				updatedDate: setDate(updatedAt) as Dayjs,
				amount: amount,
				state: setWithdrawalState(status),
				employee: {
					id: userID,
					firstName: firstName,
					lastName: lastName,
					company: {
						id: id,
						name: name
					},
					contractStatus: contractStatus as USERS_CONTRACT_STATUS
				}
			}
		}
	}
	return { record: undefined }
}
