import cx from 'classnames'
import { isNull, startCase } from 'lodash'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ACCOUNT_STATE, WITHDRAWAL_STATE, USERS_CONTRACT_STATUS } from '../utils/enums'

interface IStateItem {
	state: ACCOUNT_STATE | WITHDRAWAL_STATE | USERS_CONTRACT_STATUS | null
	nullStateLabel?: string
}

const StateItem: FC<IStateItem> = ({ state, nullStateLabel }) => {
	const { t } = useTranslation()

	return (
		<span
			className={cx(
				'inline-flex items-center whitespace-nowrap before:w-2 before:h-2 before:rounded-full before:mr-3 before:flex-shrink-0',
				{ 'before:bg-purple-base': isNull(state) },

				{ 'before:bg-success': state === ACCOUNT_STATE.ACTIVE },
				{ 'before:bg-error': state === ACCOUNT_STATE.INACTIVE || state === ACCOUNT_STATE.DEACTIVATED },

				{ 'before:bg-warning': state === WITHDRAWAL_STATE.PENDING },
				{ 'before:bg-success': state === WITHDRAWAL_STATE.APPROVED },
				{ 'before:bg-error': state === WITHDRAWAL_STATE.REJECTED },

				{ 'before:bg-warning': state === USERS_CONTRACT_STATUS.DRAFT },
				{ 'before:bg-success': state === USERS_CONTRACT_STATUS.COMPLETED },
				{ 'before:bg-error': state === USERS_CONTRACT_STATUS.EXPIRED },
				{ 'before:bg-warning': state === USERS_CONTRACT_STATUS.NONE },
				{ 'before:bg-warning': state === USERS_CONTRACT_STATUS.VIEWED },
				{ 'before:bg-warning': state === USERS_CONTRACT_STATUS.SENT }
			)}
		>
			{t('loc:general|{{label}}', { label: state ? startCase(state.toLowerCase()) : nullStateLabel })}
		</span>
	)
}

export default StateItem
